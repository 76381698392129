<template>
  <div class="">
<div class="" v-if="!(isLoadingFirstTime||isLoading)">
      <b-row class="mb-3">
           <b-col cols="12" md="4">
             <div class="v16-section-heading">Segment Name:</div>
           </b-col>
           <b-col cols="12" md="7">
             <div class="v-16-input-control">
               <input type="text" v-model="segment.name" name="" id="">
             </div>
           </b-col>
          </b-row>
          <b-row class="mb-3">
           <b-col cols="12" md="4">
             <div class="v16-section-heading">Select Quiz:</div>
           </b-col>
           <b-col cols="12" md="7">
             <div class="v-16-input-control">
              <select name="quiz"  v-model="segment.quiz_id"  id="">
                <option value="">Select Quiz</option>
                <option v-for="quiz in quizzes"
                  :key="quiz.id"
                  :value="quiz.id">
                  {{ quiz.title }}</option>
              </select>
             </div>
           </b-col>
          </b-row>
          <b-row class="mb-3" v-if="segment.quiz_id">
           <b-col cols="12" md="4">
             <div class="v16-section-heading">Definition</div>
           </b-col>
           <b-col cols="12" md="7">
            <div v-for="(stanza, stanza_index) in segment.stanzas" :key="stanza_index" class="card border-radius borderless mb-5" style="border:none;background-color: var(--grey-F8);">
  <div class="card-body">
    <div class="d-flex flex-column" style="gap:15px">
      <div class="mt-2" v-for="(criteria, criteria_index) in stanza.criteria" :key="criteria_index">
        <div>
          <label for="webhook-url" style="text-align: right;" class="text-capitalize font-color d-flex text-uppercase px-2 justify-content-between align-items-center">
            {{ criteria_index == 0 ? 'Definition' : 'OR' }}
            <div class="span text-danger" style="cursor: pointer" v-if="stanza.criteria.length > 1" @click="removeCriterion(stanza_index, criteria_index)">
              <i class="fas fa-times"></i> Remove
            </div>
          </label>
          <div class="v-16-input-control">
            <!-- v-model="criteria.question_id" -->
            <select  @change="updateStanzaCriteriaQuestion($event,stanza_index,criteria_index)">
              <option value="">
                {{ segment.quiz_id ? "Select Question" : "Select Quiz First" }}
              </option>
              <option v-for="question in selectQuestions(segment.quiz_id)" :key="question.id" :value="question.id">
                {{ question.question }}
              </option>
            </select>
          </div>
          <div>
            <div class="answer mt-2">
              <label for="webhook-url" class="font-color">
                <span> Answer is:</span>
              </label>
              <div class="v-16-input-control">
                <select v-model="criteria.option_id">
                  <option value="">
                    {{ segment.quiz_id ? "Select Answer" : "Select Quiz First" }}
                  </option>
                  <option v-for="option in selectOptions(criteria.question_id)" :key="option.id" :value="option.id">
                    {{ option.option_value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex justify-content-start" v-if="criteria_index + 1 == stanza.criteria.length">
              <div class="condition" style="cursor:pointer" @click="stanza.criteria.push({ question_id: '', option_id: '' })">
                OR
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
           </b-col>
          </b-row>
        </div>
        <div class="" v-else>
          <b-row class="mb-3">
           <b-col cols="12" md="4">
             <div class="v16-section-heading">Segment Name:</div>
           </b-col>
           <b-col cols="12" md="7">
            <div
                    
                    class="integrationImgae bg-preload"
                    style="height: 50px; width: 100%; border-radius: 14px"
                  >
                    <div class="into-preload"></div>
                  </div>
           </b-col>
          </b-row>
          <b-row class="mb-3">
           <b-col cols="12" md="4">
             <div class="v16-section-heading">Select Quiz:</div>
           </b-col>
           <b-col cols="12" md="7">
            <div
                    
                    class="integrationImgae bg-preload"
                    style="height: 50px; width: 100%; border-radius: 14px"
                  >
                    <div class="into-preload"></div>
                  </div>
           </b-col>
          </b-row>

        </div>
    <FooterContent >
<div class="d-flex align-items-center justify-content-end">
  <div
               class="px-3 py-2 mx-2"
               @click="ANDCondition()" style="
border: 1px solid #73738D;
border-radius: 6px;
        min-width: 80px;
    font-size: 14px;
    cursor: pointer;
  
">
               + AND
             </div>
            <Button :is-loading="isLoadingSubmitBtn" @click="upload()"
              >{{ isEditMode?'Update':'Create' }} Segment</Button
            >
</div>
</FooterContent>
  </div>
</template>
<script>
import Button from "../../Layout/Button.vue";
import axios from "axios";

import FooterContent from '../../Layout/FooterContent.vue';
export default {
  props: {
    title: {
      type: String,
      default: "Segment",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isLoadingSubmitBtn: {
      type: Boolean,
      default: false,
    },
    isLoadingFirstTime:{
 type: Boolean,
      default: false,
    },
   segments: {
    type: Object,
    default: () => {return {
      name: "",
      quiz_id: "",
      "stanzas": [
        {
            "criteria": [
               
                {
                    "question_id": '',
                    "option_id": ''
                }
            ]
        },
       
    ]
    }},
  },
  },
  data() {
    return {
      quizzes: [],
      selectedQuestion: [],
      SelectedQuiz: [],
      isLoading:false,
      segment: {...this.segments},
      stanza:[]
    };
  },

  components: {
    FooterContent,
    Button,
  },
  computed:{
    ModalId(){
      return this.isEditMode?'EditModule':'CreateModule'
    },
hasEmptyCriteria() {
      for (const stanza of this.segment.stanzas) {
        for (const criterion of stanza.criteria) {
          if (!criterion.question_id || !criterion.option_id) {
            return true;
          }
        }
      }
      return false;
    }
  },
  methods: {
    updateStanzaCriteriaQuestion(e,stanzaIndex,criteriaIndex){
      this.segment.stanzas[stanzaIndex].criteria[criteriaIndex].question_id=e.target.value
    },
    async upload() {
      const invalidation = this.validateSegment();
      if (!invalidation) this.$emit("submit", this.segment);
    },
    validateSegment() {
      let isInvalid = false;
      let message = "";
      if (this.segment.name == "") {
        isInvalid = true;
        message = "Segment name must be required";
      } else if (this.segment.quiz_id == "") {
        isInvalid = true;
        message = "Please select quiz";
      } else if (this.hasEmptyCriteria) {
        isInvalid = true;
        message = "Fill all the criteria";
      }
      this.$toasted.show(message, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
      return isInvalid;
    },
  
    openModal() {
      try {
        this.$refs[this.ModalId].open();
        if (!this.isEditMode)
          this.segment = {
            name: "",
            quiz_id: "",
             "stanzas": [
        {
            "criteria": [
               
                {
                    "question_id": '',
                    "option_id": ''
                }
                
            ]
        },
       
    ]
          };
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      this.$refs[this.ModalId].close();
    },
    async getQuizzes() {
      this.isLoading = true;
      await axios
        .post("/loadDetailedQuizzes")
        .then((response) => {
          this.quizzes = response.data.data.quizzes;
        
         
        })
        .catch(() => []);
      this.isLoading = false;
    },
    selectOptions(id) {
      let selectedQuizIndex = this.quizzes.findIndex(
        (e) => e.id == this.segment.quiz_id
      );
      if (selectedQuizIndex > -1) {
        let selectedQuestions = this.quizzes[selectedQuizIndex].questions;

        let currentQuestionIndex = selectedQuestions.findIndex(
          (e) => e.id == id
        );

        if (currentQuestionIndex > -1) {
          return selectedQuestions[currentQuestionIndex].options;
        }
      }
      return [];

     
    },
  
    selectQuestions(id) {
      const question = this.quizzes.filter((quiz) => quiz.id == id);
      if (question.length > 0) {
        return question[0].questions;
      }
      return [];
    },
     removeCriterion(stanzaIndex, criterionIndex) {
       if( this.segment.stanzas[stanzaIndex].criteria.length==1){
        this.segment.stanzas.splice(stanzaIndex, 1);
      }
      else
      this.segment.stanzas[stanzaIndex].criteria.splice(criterionIndex, 1);
    },
    ANDCondition(){
      this.segment.stanzas.push({
            "criteria": [
               
                {
                    "question_id": '',
                    "option_id": ''
                }
                
            ]
        })


        this.$nextTick(() => {
      // Ensure the DOM is updated
      const modalContent = this.$refs.addSegmentDialogContainer;
      if (modalContent) {
        modalContent.scrollTo({
          top: modalContent.scrollHeight, // Scroll to the bottom
          behavior: 'smooth' // Enable smooth scrolling
        });
      }
    });
    }
  },
  watch:{
    segments:{
        handler(newVal){
            this.segment={...newVal}
        },
        deep:true,
    }
  },
  created(){
    this.segment = JSON.parse(JSON.stringify(this.segments));
  },
  mounted() {
    this.getQuizzes();
  },
};
</script>

<style scoped>
.quizell-form-control {
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d2d8e0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.3 ease all;
  background-color: transparent;
}
.question-control {
  border: 1px solid rgba(255, 162, 1, 1);
}
quizell-form-control .quizell-form-control:focus-visible {
  border: none !important;
  outline: none;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.font-color{
   color: var(--grey-85);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.condition {
  display: inline-flex;
  padding: 16px 14px;
  align-items: center;
  gap: 4px;
  color: #0d7de4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.LoadingTitle{
  color: #1F1F28;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.72px;
text-align: center;
}

.LoadingSubText{
  color: #7C8290;
  text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
}
</style>
