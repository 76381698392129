<template>
    <div class="">
       
        <Modal :isLoadingFirstTime="isLoading" :isEditMode="true" :segments="segment" :isLoadingSubmitBtn="isLoadingSubmitBtn" @submit="saveSegment($event)" title="Update Segment" ref="updateSegment"></Modal>
        
            </div>
</template>
<script>

import Modal from './Modal.vue';
import axios from 'axios';
export default {
    props:{
        loadSegments:Function
    },
components:{
    Modal,
    
},
data(){
    return {
        isLoading:false,
        isLoadingSubmitBtn:false,
        segment:{},
    }
},
methods:{
   async  openModal(id){
    this.isLoading=true
            await axios.get('/api/v1/segments/'+id).then((res)=>{
           this.segment=res.data.data.segment

        }).catch(()=>{
             
        })
          this.isLoading=false
    },
    async saveSegment(data){
        this.isLoadingSubmitBtn=true;
        let payload = {...data}
        
        await axios.put('/api/v1/segments/'+data.id,payload).then(()=>{
           
              this.$toasted.show("Segment updating successfully", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            this.$router.push({name:'SegmentsLeads'}) 
        }).catch(()=>{
              this.$toasted.show("Error while updating segment", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
        })
       this.isLoadingSubmitBtn=false;
    }
},
mounted(){
   try{
    const id =atob(this.$route.params.id).split('-')[0]
    this.openModal(id)
   }
   catch(e){
    this.$toasted.show("Incorrect id, Use FValid Id", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            this.$router.push({name:'Segments'});
            console.log(e)
   }
    
    
}
}
</script>
